import React, { useState } from "react";
import { AddCamera } from "../components/form/AddCamera";
import { EditCamera } from "../components/form/EditCamera";
import { RoofImage } from "../components/RoofImage";
import { SelectDropdown } from "../components/SelectDropdown";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useAuth } from "../components/auth/AuthProvider";

export const CameraPage = () => {
  const { user } = useAuth(); // Get the authenticated user
  // State to track the selected camera
  const [selectedCamera, setSelectedCamera] = useState({}); // To track the selected camera ID
  // State to track which section is currently active
  const [currentSection, setCurrentSection] = useState("");
  // Handle changes to the selected camera (this could be from a future camera dropdown)
  const handleCameraChange = (camera) => {
    setSelectedCamera(camera); // Update the selected camera
  };

  // Handle changing the active section
  const handleSectionChange = (section) => {
    setCurrentSection(section);
    setSelectedCamera({}); // Update the current section (view images, add camera, edit camera)
  };

  return (
    <Container fluid className="camerapage">
      <Row className="mt-3">
        <Col>
          <h1>Camera Management</h1>
        </Col>
      </Row>

      {/* Button group to switch between different sections */}
      <Row className="mt-2 justify-content-center">
        <Col className="d-flex justify-content-center flex-wrap">
          <Button
            variant="primary"
            onClick={() => handleSectionChange("viewImages")}
          >
            View Images
          </Button>

          {/* Conditionally render Add Camera and Edit Camera buttons for admin role */}
          {user?.role === "admin" && (
            <>
              <Button
                variant="success"
                onClick={() => handleSectionChange("addCamera")}
              >
                Add Camera
              </Button>

              <Button
                variant="warning"
                onClick={() => handleSectionChange("editCamera")}
              >
                Edit Camera
              </Button>
            </>
          )}
        </Col>
      </Row>

      {/* Render the appropriate section based on the button clicked */}
      <Row className="mt-2 justify-content-center">
        <Col className="d-flex flex-column justify-content-center align-items-center">
          {currentSection === "viewImages" && (
            <>
              <Row>
                {/* Dropdown in its own row */}
                <Col style={{ maxWidth: "300px" }}>
                  <SelectDropdown
                    source="camera"
                    onChange={handleCameraChange}
                  />
                </Col>
              </Row>
              <Row>
                {/* Display images in a separate row */}
                <Col className="image-column mt-2">
                  {selectedCamera.id && (
                    <RoofImage cameraId={selectedCamera.id} />
                  )}
                </Col>
              </Row>
            </>
          )}

          {currentSection === "addCamera" && <AddCamera />}

          {currentSection === "editCamera" && <EditCamera />}
        </Col>
      </Row>
    </Container>
  );
};
