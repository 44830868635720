import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

/**
 * Renders a line chart component.
 *
 * @returns {JSX.Element} The line chart component.
 */
export const LineChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    /**
     * Defines an async function inside useEffect.
     *
     * https://devtrium.com/posts/async-functions-useeffect#write-the-asynchronous-function-inside-the-useeffect
     *
     * By using an async IIFE inside the useEffect,
     * we ensure that the data fetching and processing operations
     * do not 'block' the rendering of the component.
     *
     * This 'non-blocking' effect allows the component to remain
     * responsive while the data is being fetched and updated periodically,
     * in the background.
     *
     * There is a good explanation of async/await vs. Promise-based code, here:
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
     */

    // declare the data fetching function
    const fetchData = async () => {
      try {
        const response = await fetch("/results"); // this returns a Promise, so we need an await keyword to 'wait' for the Promise to resolve.
        const result = await response.json(); // this returns a Promise, so we need an await keyword to 'wait' for the Promise to resolve.
        const results = [...result.results];
        results.reverse();

        // Process the data to extract timestamps and graph values
        const timestamps = results.map((item) =>
          new Date(item.created_at).toLocaleTimeString()
        );
        const graphValues = results.map((item) => item.json_data.graphValue);

        // Update the state with processed data
        setData({ timestamps, graphValues });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Immediately invoke the async function (note: this returns a promise, BUT we don't use await. This is to create the 'non-blocking' effect mentioned above.)
    void fetchData();

    const intervalId = setInterval(fetchData, 7200000); // Poll every 1 hour

    // Cleanup interval on component unmount
    // See: https://react.dev/reference/react/useEffect
    // (and in particular, the notes section)
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Plot
      data={[
        {
          x: data.timestamps,
          y: data.graphValues,
          type: "scatter",
          mode: "lines+markers",
          marker: { color: "blue" },
        },
      ]}
      layout={{
        title: "Chart",
        xaxis: {
          title: "Time",
          range: [0, Math.max(...(data.timestamps || []), 1)],
        },
        yaxis: {
          title: "Value",
          range: [0, Math.max(...(data.graphValues || []), 100)],
        },
      }}
      style={{ width: "100%" }}
    />
  );
};
