import { useEffect, useState } from "react";

export const useCsrfToken = () => {
  const [csrfToken, setCsrfToken] = useState(null);
  const [csrfLoading, setLoading] = useState(true);
  const [csrfError, setError] = useState(null);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch("/auth/csrf-token", {
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch CSRF token");
        }
        const data = await response.json();
        setCsrfToken(data.csrf_token);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchCsrfToken();
  }, []);

  return { csrfToken, csrfLoading, csrfError };
};
