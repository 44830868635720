import React from "react";
import { useAuth } from "./AuthProvider";
import { Navigate, useLocation } from "react-router-dom";

export const ProtectedRoute = ({ allowedRoles, children }) => {
  const { isAuthenticated, user } = useAuth(); // Access the authentication state from the AuthProvider
  const location = useLocation(); // Get current location

  // If the user is not authenticated, render a 401 Unauthorized error page
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // If the user does not have the required role, render a 403 Forbidden error page
  if (allowedRoles && !allowedRoles.includes(user?.role)) {
    return <ErrorPage statusCode={403} />;
  }
  // If the user is authenticated, render the protected component
  return children;
};
