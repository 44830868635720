import React from "react";
import { useAuth } from "../components/auth/AuthProvider";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
/**
 * Renders the Header with the specified navigation component
 *
 * @returns {React.Component} Header
 */
export const Header = () => {
  const { isAuthenticated, user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (err) {
      console.error("Logout failed", err);
    }
  };

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      className="navbar"
      collapseOnSelect="true"
    >
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>RPS Dashboard</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {isAuthenticated && (
              <>
                <LinkContainer to="/home">
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/roof">
                  <Nav.Link>Roof</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/camera">
                  <Nav.Link>Camera</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/chart">
                  <Nav.Link>Test</Nav.Link>
                </LinkContainer>
              </>
            )}

            {/* Show Register link only if the user is admin */}
            {user?.role === "admin" && (
              <LinkContainer to="/registration">
                <Nav.Link>Register</Nav.Link>
              </LinkContainer>
            )}
          </Nav>

          <Nav>
            {isAuthenticated ? (
              <div className="nav-authenticated">
                <Navbar.Text className="me-3">
                  Welcome, {user?.username || ""}
                </Navbar.Text>
                <Button variant="outline-light" onClick={handleLogout}>
                  Logout
                </Button>
              </div>
            ) : (
              // Only show Login link if not on the login page
              location.pathname !== "/login" && (
                <LinkContainer to="/login">
                  <Button variant="outline-light">Login</Button>
                </LinkContainer>
              )
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
