import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
// import monashLogo from "../../assets/monash_logo.png";

export const HomePage = () => {
  return (
    <Container className="homepage">
      <Row className="mb-4">
        <Col>
          <h1>Welcome!</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={6} className="logo">
          <Image
            className="mu-logo"
            src="./assets/mu_logo.png"
            alt="Monash Logo"
            fluid
          />
        </Col>
        <Col xs={6} md={6} className="logo">
          <Image
            className="uom-logo"
            src="./assets/uom_logo.png"
            alt="University Of Melbourne Logo"
            fluid
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={6} className="logo">
          <Image
            className="mp-logo"
            src="./assets/mp_logo.png"
            alt="Melbourne Poly Logo"
            fluid
          />
        </Col>
        <Col xs={6} md={6} className="logo">
          <Image
            className="du-logo"
            src="./assets/deakin_logo.png"
            alt="Deakin Logo"
            fluid
          />
        </Col>
      </Row>
    </Container>
  );
};
