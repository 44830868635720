import { useState, useCallback } from "react";

export const useAerialImage = () => {
  const [aerialImage, setAerialImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);

  const handleFetchAerialImage = useCallback(async (buildingId) => {
    try {
      const response = await fetch(`/api/building_image?id=${buildingId}`);
      const result = await response.json();
      setAerialImage(result.presigned_url);
      setShowImageModal(true);
    } catch (error) {
      console.error("Error fetching building aerial image:", error);
    }
  }, []);

  const closeImageModal = useCallback(() => setShowImageModal(false), []);
  return {
    aerialImage,
    showImageModal,
    handleFetchAerialImage,
    closeImageModal,
  };
};
