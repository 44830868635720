import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from "./AuthProvider";
import { useCsrfToken } from "../utils/useCsrfToken";
// Login form component with username and password fields.
/**
 *
 * @returns {JSX.Element} login form
 */

export const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  const { csrfToken, loading, error } = useCsrfToken();

  const handleLogin = async (event) => {
    event.preventDefault();
    setMessage("");

    if (loading) return; // Prevent form submission if CSRF token is still loading

    try {
      const response = await fetch("/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        credentials: "include", // Include authentication cookies in the request
      });

      const result = await response.json();

      if (response.ok) {
        login(result.user);
        const from =
          location.state?.from?.pathname || result.redirect || "/home";

        // Prevent redirect to login page if user is already on it
        if (from !== "/login") {
          navigate(from);
        } else {
          navigate("/home"); // Default to "/home" or any other route if on login page
        }
      } else {
        setMessage(result.message || "Login failed");
      }
    } catch (error) {
      setMessage("An error occurred while logging in.");
    }
  };

  if (error) {
    return (
      <Alert variant="danger" className="mt-5">
        Failed to load CSRF token:
        {error}
      </Alert>
    );
  }

  return (
    <div className="login-form">
      <Form onSubmit={handleLogin}>
        <Form.Group controlId="username" className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="password" className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>

        {message && (
          <Alert variant="danger" className="mb-3">
            {message}
          </Alert>
        )}

        <Button variant="primary" type="submit" className="w-100">
          Login
        </Button>
      </Form>
    </div>
  );
};
