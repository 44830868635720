import React from "react";
import { Modal, Button } from "react-bootstrap";

const JobLinkModal = ({ show, handleClose, jobUrl }) => (
  <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Job Created Successfully!</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        The job has been created successfully. You can view the job at the
        following link:
      </p>
      {jobUrl && (
        <a href={jobUrl} target="_blank" rel="noopener noreferrer">
          View Job
        </a>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default JobLinkModal;
