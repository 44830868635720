import React, { createContext, useState, useEffect, useContext } from "react";
import { useCsrfToken } from "../utils/useCsrfToken";
// Create a context for authentication
const AuthContext = createContext();

// Export useAuth hook to access authentication state in any component
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const { csrfToken, csrfError, csrfLoading } = useCsrfToken();

  // Fetch user authentication status and user data on component mount
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch("/auth/check-auth", {
          credentials: "include",
        });
        const result = await response.json();

        if (response.ok) {
          setIsAuthenticated(true);

          setUser(result.user);
        } else {
          setIsAuthenticated(false);
          setUser(null); // Clear user data if authentication fails
        }
      } catch (error) {
        console.log(error);
        setIsAuthenticated(false);
        setUser(null); // Clear user data if authentication fails
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const login = (userData) => {
    setIsAuthenticated(true);
    setUser(userData);
  };

  const logout = async () => {
    if (!csrfToken || csrfLoading || csrfError) {
      console.error("CSRF token not available for logout request");
      return; // Return early if CSRF token is not available or loading or there is an error with the token request
    }

    try {
      // Log CSRF token before sending the request
      console.log("CSRF Token (frontend):", csrfToken);
      const response = await fetch("/auth/logout", {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        // Clear user session on the client side
        setIsAuthenticated(false);
        setUser(null);
      } else {
        console.log("Failed to log out");
      }
    } catch (error) {
      console.log("Failed to log out", error);
    }
  };

  // Handle CSRF loading or errors
  if (csrfLoading) {
    return <div>Loading CSRF token...</div>;
  }

  if (csrfError) {
    return <div>Error loading CSRF token: {csrfError}</div>;
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, loading, login, logout }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};
