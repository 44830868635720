import React from "react";
import { LineChart } from "../components/LineChart";
import { Container, Row, Col } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

export const ChartPage = () => {
  return (
    <Container className="chartpage">
      <Row className="mb-4">
        <Col>
          <h1>Hello, world!</h1>
          <LineChart />
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup className="todo-list">
            <h2>Frontend To-do list</h2>

            <ListGroup.Item>
              Add "create task" from aroflo - setup API
            </ListGroup.Item>

            <ListGroup.Item>Add links for current dashboards</ListGroup.Item>
            <h2>Backend To-do List</h2>
            <ListGroup.Item>
              Add columns to image table in postgres database
            </ListGroup.Item>
            <ListGroup.Item>
              Add mask table - understand what the mask is used for and when
              it's required, assign FK
            </ListGroup.Item>
            <ListGroup.Item>
              Add building info, image, database, filestorage
            </ListGroup.Item>
            <ListGroup.Item>Check session cache</ListGroup.Item>
            <ListGroup.Item>Too many more to list...</ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};
