import React, { useState } from "react";
import { RoofByBuilding } from "../layouts/RoofByBuilding";
import { RoofOverview } from "../layouts/RoofOverview";
import { Button, Row, Col, Container } from "react-bootstrap";
import { useAuth } from "../components/auth/AuthProvider";
import { useCsrfToken } from "../components/utils/useCsrfToken";

export const RoofPage = () => {
  const { user } = useAuth(); // Get the authenticated user
  const { csrfToken, csrfError } = useCsrfToken();

  // State to toggle between overview and building view
  const [viewMode, setViewMode] = useState("overview"); // 'overview' or 'building'

  // Switch to overview mode
  const handleShowOverview = () => setViewMode("overview");

  // Switch to building selection mode
  const handleShowBuildingView = () => setViewMode("building");

  if (csrfError) {
    return <div>Error loading CSRF token: {csrfError}</div>;
  }

  return (
    <Container fluid className="roofpage">
      {/* <Header /> */}
      <Row className="mt-3">
        <Col className="d-flex justify-content-center align-items-center">
          <h1>Roof Management</h1>
        </Col>
      </Row>
      {/* SelectBuilding component */}
      <Row className="mt-2 justify-content-center">
        <Col className="d-flex justify-content-center flex-wrap">
          <Button
            variant={viewMode === "overview" ? "primary" : "outline-secondary"}
            onClick={handleShowOverview}
          >
            Overview
          </Button>
          <Button
            variant={viewMode === "building" ? "primary" : "outline-secondary"}
            className="ml-2"
            onClick={handleShowBuildingView}
          >
            View by Building
          </Button>
        </Col>
      </Row>
      <Row className="mt-2 justify-content-center">
        <Col className="d-flex flex-column justify-content-center align-items-center">
          {/* Render the selected view */}
          {viewMode === "overview" ? (
            <RoofOverview />
          ) : (
            <RoofByBuilding user={user} csrfToken={csrfToken} />
          )}
        </Col>
      </Row>
    </Container>
  );
};
