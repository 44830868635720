import React, { useEffect, useState } from "react";
import { Form, Button, Alert, Container } from "react-bootstrap";
import { SelectDropdown } from "../SelectDropdown";
import { useCsrfToken } from "../utils/useCsrfToken";

export const EditCamera = () => {
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [cameraData, setCameraData] = useState({
    camera_name: "",
    camera_number: "",
    camid: "",
    latitude: "",
    longitude: "",
    active: false,
    location_id: "",
    location_name: "",
  });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { csrfToken, csrfLoading, csrfError } = useCsrfToken();

  // Fetch the camera data when the component mounts
  useEffect(() => {
    const fetchCameraData = async () => {
      if (!selectedCamera) return;

      try {
        const response = await fetch(`/api/get_camera/${selectedCamera.id}`);
        const result = await response.json();

        if (response.ok) {
          // Populate the cameraData state with the fetched data
          setCameraData({
            camera_name: result.camera_name || "",
            camera_number: result.camera_number || "",
            camid: result.id || "",
            latitude: result.latitude || "",
            longitude: result.longitude || "",
            active: result.active || false,
            location_id: result.location_id || "",
            location_name: result.location_name || "",
          });
          setMessage("");
        } else {
          setMessage(result.error || "Camera not found");
        }
      } catch (error) {
        setMessage("Failed to fetch camera data.");
      }
    };

    fetchCameraData();
  }, [selectedCamera]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCameraData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle building selection
  const handleBuildingChange = (building) => {
    setCameraData((prevData) => ({
      ...prevData,
      location_id: building.id,
      location_name: building.name,
    }));
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    if (csrfLoading) return; // Prevent form submission if CSRF token is still loading
    e.preventDefault();
    setLoading(true);
    setMessage("");
    try {
      const response = await fetch(`/api/edit_camera/${selectedCamera.id}`, {
        method: "PUT",
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cameraData),
        credentials: "include",
      });

      const result = await response.json();

      if (response.ok) {
        setCameraData({
          camera_name: "",
          camera_number: "",
          camid: "",
          latitude: "",
          longitude: "",
          active: false,
          location_id: "",
          location_name: "",
        });
        setSelectedCamera(null);
        setMessage("Camera updated successfully!");
      } else {
        setMessage(result.error || "Error updating camera.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setMessage("Error submitting form.");
    } finally {
      setLoading(false);
    }
  };
  // Handle CSRF loading or errors
  if (csrfLoading) {
    return <div>Loading CSRF token...</div>;
  }

  if (csrfError) {
    return <div>Error loading CSRF token: {csrfError}</div>;
  }

  return (
    <Container className="editcamera-form d-flex flex-column justify-content-center align-items-center">
      <h2>Edit Camera</h2>
      {message && <Alert variant="danger">{message}</Alert>}

      {/* Camera selection dropdown */}
      <Form.Group className="mb-3">
        <SelectDropdown
          source="camera"
          value={
            selectedCamera
              ? { value: selectedCamera.id, label: selectedCamera.id }
              : null
          }
          onChange={(camera) => setSelectedCamera(camera)} // Update selected camera
          placeholder="Select Camera"
        />
      </Form.Group>

      {/* Show form only when a camera is selected */}
      {selectedCamera && (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="cameraName">
            <Form.Label>Camera Name</Form.Label>
            <Form.Control
              type="text"
              name="camera_name"
              value={cameraData.camera_name}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="cameraNumber">
            <Form.Label>Camera Number</Form.Label>
            <Form.Control
              type="number"
              name="camera_number"
              value={cameraData.camera_number}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="latitude">
            <Form.Label>Latitude</Form.Label>
            <Form.Control
              type="text"
              name="latitude"
              value={cameraData.latitude}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="longitude">
            <Form.Label>Longitude</Form.Label>
            <Form.Control
              type="text"
              name="longitude"
              value={cameraData.longitude}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="active">
            <Form.Check
              type="checkbox"
              label="Active"
              name="active"
              checked={cameraData.active}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Select Location</Form.Label>
            <SelectDropdown
              source="building"
              onChange={handleBuildingChange}
              value={
                cameraData.location_id
                  ? {
                      value: cameraData.location_id,
                      label: cameraData.location_name,
                    }
                  : { value: null, label: "Not Assigned" }
              } // Pass the selected location value to the dropdown
              selected={cameraData.location_id} // Pass the selected location
              allowNotAssigned={true} // Allow a "Not Assigned" option
            />
          </Form.Group>

          <Button type="submit" disabled={loading || csrfLoading}>
            {loading ? "Updating..." : "Update Camera"}
          </Button>
        </Form>
      )}
    </Container>
  );
};
