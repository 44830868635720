import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./src/components/auth/ProtectedRoute";
import { Header } from "./src/layouts/Header";
import { useAuth } from "./src/components/auth/AuthProvider";
import { HomePage } from "./src/pages/HomePage";
import { LoginPage } from "./src/pages/LoginPage";
import { RoofPage } from "./src/pages/RoofPage";
import { CameraPage } from "./src/pages/CameraPage";
import { RegisterPage } from "./src/pages/RegisterPage";
import { LandingPage } from "./src/pages/LandingPage";
import { ChartPage } from "./src/pages/ChartPage";
import { Spinner } from "react-bootstrap";
import ErrorPage from "./src/pages/ErrorPage";

// App component that handles routing and authentication
export const App = () => {
  const { loading } = useAuth();

  if (loading) {
    return (
      <div className="loading-screen">
        <Spinner animation="border" />
      </div>
    ); // You can render a loading spinner or similar here
  }
  return (
    <div>
      <Header />

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/roof"
          element={
            <ProtectedRoute>
              <RoofPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/camera"
          element={
            <ProtectedRoute>
              <CameraPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chart"
          element={
            <ProtectedRoute>
              <ChartPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/registration"
          element={
            <ProtectedRoute allowedRoles={["admin", "superuser"]}>
              <RegisterPage />
            </ProtectedRoute>
          }
        />
        {/* Handle 404 Page Not Found */}
        <Route path="*" element={<ErrorPage statusCode={404} />} />
      </Routes>
    </div>
  );
};
