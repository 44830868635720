// components/RegisterForm.jsx
import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useCsrfToken } from "../utils/useCsrfToken";

export const RegisterForm = ({
  onRegisterSuccess = () => console.log("Default success function called"),
}) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false); // State for tracking registration success
  const { csrfToken, loading, error } = useCsrfToken(); // State for CSRF token

  const handleRegister = async (event) => {
    event.preventDefault();
    setMessage("");
    setSuccess(false);

    try {
      const response = await fetch("/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({
          username: username,
          email: email,
          password: password,
        }),
      });
      const result = await response.json(); // Fetch JSON result
      if (response.ok) {
        onRegisterSuccess();
        setMessage("Registration Complete!"); // display success message
        setSuccess(true);
        // Clear form fields after successful registration
        setUsername("");
        setEmail("");
        setPassword("");
      } else {
        setMessage(result.message || "Registration failed");
      }
    } catch (error) {
      console.log(error);
      setMessage("An error occurred during registration.");
    }
  };

  if (error) {
    return <div>Error loading CSRF token: {error}</div>;
  }

  return (
    <div className="registration-form">
      <Form onSubmit={handleRegister}>
        <Form.Group controlId="username" className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="email" className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="password" className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>

        {message && (
          <Alert variant={success ? "success" : "danger"} className="mb-3">
            {message}
          </Alert>
        )}

        <Button
          variant="primary"
          type="submit"
          className="w-100"
          disabled={loading}
        >
          {loading ? "Loading..." : "Register"}
        </Button>
      </Form>
    </div>
  );
};
