import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { RegisterForm } from "../components/auth/RegisterForm";

// Login page with login
export const RegisterPage = () => {
  const handleRegisterSuccess = () => {
    alert("Registration successful! You can now log in.");
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={6}>
          <RegisterForm onRegisterSuccess={handleRegisterSuccess} />
        </Col>
      </Row>
    </Container>
  );
};
