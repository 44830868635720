import React, { useState, useCallback } from "react";
import { RoofImage } from "../components/RoofImage";
import { SelectDropdown } from "../components/SelectDropdown";
import { Button, Row, Col, Container } from "react-bootstrap";
import { useJobCreation } from "../components/utils/useJobCreation";
import { useAerialImage } from "../components/utils/useAerialImage";
import JobCreationModal from "../components/modal/JobCreationModal";
import AerialImageModal from "../components/modal/AerialImageModal";
import JobLinkModal from "../components/modal/JobLinkModal";

export const RoofByBuilding = ({ user, csrfToken }) => {
  // State to store selected building
  const [selectedBuilding, setSelectedBuilding] = useState({});
  // State to store selected cameras
  const [selectedCameras, setSelectedCameras] = useState([]);
  // State to store aerial image data
  const {
    aerialImage,
    handleFetchAerialImage,
    showImageModal,
    closeImageModal,
  } = useAerialImage();
  // State to store job creation data
  const {
    handleCreateJob,
    jobUrl,
    showJobModal,
    setShowJobModal,
    showJobLinkModal,
    closeJobModal,
    closeJobLinkModal,
    loading,
  } = useJobCreation(csrfToken, selectedBuilding);

  // Handle building change from SelectBuilding component
  const handleBuildingChange = useCallback((building) => {
    setSelectedBuilding(building); // Update the selected building
  }, []);

  const handleDataFetched = useCallback((data) => {
    setSelectedCameras(data); // Update the selected cameras
  }, []);

  return (
    <Container fluid>
      {/* <Header /> */}

      {/* SelectBuilding component */}
      <Row>
        <Col className="d-flex flex-row justify-content-center align-items-center flex-wrap">
          <SelectDropdown source="building" onChange={handleBuildingChange} />

          <Button
            onClick={() => handleFetchAerialImage(selectedBuilding.id)}
            disabled={!selectedBuilding.id || loading}
            variant="info"
          >
            {loading ? "Fetching Image..." : "Show Aerial Image"}
          </Button>

          {/* Button for job creation in aroflo */}
          <Button
            onClick={() => setShowJobModal(true)}
            disabled={!selectedBuilding.id || user?.role !== "admin"}
            variant="warning"
          >
            Create Job
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{selectedBuilding.name}</h2>
        </Col>
      </Row>

      {/* Render RoofImage component only if a building is selected */}
      {selectedBuilding.id && (
        <RoofImage
          buildingId={selectedBuilding.id}
          onDataFetched={handleDataFetched}
        />
      )}

      {/* Modal for displaying the aerial image */}
      <AerialImageModal
        show={showImageModal}
        handleClose={closeImageModal}
        aerialImage={aerialImage}
        buildingName={selectedBuilding.name}
      />

      {/* Modal for job creation */}
      <JobCreationModal
        show={showJobModal}
        handleClose={closeJobModal}
        handleCreateJob={handleCreateJob}
        loading={loading}
        selectedCameras={selectedCameras}
      />

      {/* Modal for showing the job link */}
      <JobLinkModal
        show={showJobLinkModal}
        handleClose={closeJobLinkModal}
        jobUrl={jobUrl}
      />
    </Container>
  );
};
