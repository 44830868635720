import React, { useEffect, useState } from "react";
import Select from "react-select";

/**
 * Renders a select dropdown for buildings or cameras.
 *
 * @param {string} source - Type of dropdown (either "building" or "camera").
 * @param {function} onChange - Callback function to pass selected value to the parent component.
 * @param {object} value - Controlled value for the dropdown (selected option).
 * @param {boolean} allowNotAssigned - Whether to allow a "Not Assigned" option.
 * @returns {JSX.Element} The select dropdown component.
 */

export const SelectDropdown = ({
  source,
  onChange,
  value,
  allowNotAssigned = false,
}) => {
  // State to store the list of options
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false); // Track loading state

  // Fetch list of options from API
  useEffect(() => {
    const fetchOptions = async () => {
      setLoading(true);
      try {
        const endpoint = `/api/list_results/${source}`; // Dynamically construct the route based on the source (building or camera)
        const response = await fetch(endpoint); // API endpoint for fetching cameras
        const data = await response.json(); // Assume the API returns a JSON array of camera names
        // Format options for React-Select: { value: option.id, label: option.building_name/camera_name }
        const formattedOptions = data.results.map((option) => ({
          value: option.id,
          label:
            source === "building"
              ? `${option.building_name} (bld ${option.building_number})`
              : option.id,
        }));

        // Optionally add a "Not Assigned" option
        if (allowNotAssigned) {
          formattedOptions.unshift({
            value: null,
            label: "Not Assigned",
          });
        }

        setOptions(formattedOptions); // Set camera list in state
      } catch (error) {
        console.error("Error fetching camera list:", error);
      } finally {
        setLoading(false); // Reset loading state after fetching data or error
      }
    };

    fetchOptions(); // Fetch cameras when the component mounts
  }, [source, allowNotAssigned]); // Empty dependency array ensures this runs once

  const handleChange = (selectedOption) => {
    // Call the onChange callback with the selected option's value and label
    onChange({
      id: selectedOption.value,
      name: selectedOption.label,
    });
  };

  return (
    <Select
      className={`dropdown-option-${source}`}
      options={options} // List of options
      isSearchable={true} // Enable search
      onChange={handleChange} // Handle selection change
      value={value} // Controlled value to reset dropdown selection
      placeholder={`Select ${source}`} // Placeholder text
      isDisabled={loading}
    />
  );
};
