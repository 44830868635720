import React from "react";
import { Modal, Image, Button } from "react-bootstrap";

const AerialImageModal = ({ show, handleClose, aerialImage, buildingName }) => {
  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header>
        <Modal.Title className="text-uppercase">
          Aerial Image of {buildingName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {aerialImage ? (
          <Image
            src={aerialImage}
            alt={`Aerial view of ${buildingName}`}
            fluid
          />
        ) : (
          <p>No aerial image available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AerialImageModal;
