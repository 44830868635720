import { useState, useEffect } from "react";

export const useRoofImages = (buildingId, cameraId, onDataFetched) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setPage(1);
    setData([]);
    setHasMore(true);
  }, [buildingId, cameraId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let query = "";
        let limit = 6;
        if (buildingId) {
          query = `/api/image_results/building?id=${encodeURIComponent(
            buildingId
          )}`;
          limit = 0;
        } else if (cameraId) {
          query = `/api/image_results/camera?id=${encodeURIComponent(
            cameraId
          )}&page=${page}&limit=6`;
        } else {
          console.warn("Neither buildingId nor cameraId provided.");
          return;
        }

        const response = await fetch(query);
        const result = await response.json();

        // Check if onDataFetched is a function before calling it
        if (typeof onDataFetched === "function") {
          onDataFetched(result.results);
        }
        if (cameraId && result.results.length < limit) {
          setHasMore(false);
        }
        setData((prevData) =>
          page === 1 ? result.results : [...prevData, ...result.results]
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if ((buildingId || cameraId) && page > 0) {
      fetchData();
    }
  }, [buildingId, cameraId, page, onDataFetched]);

  const loadMore = () => setPage((prevPage) => prevPage + 1);

  return { data, loading, hasMore, loadMore };
};
