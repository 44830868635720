import React from "react";

export const RoofOverview = () => {
  return (
    <div>
      <h2>Overview of All Roofs</h2>
      <p>This section provides an overview of all buildings or roofs.</p>
      <p>Active Cameras. Cameras inactivity</p>
      {/* Add more general statistics, charts, or a list of buildings here */}
    </div>
  );
};
