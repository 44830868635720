import { useState, useCallback } from "react";

export const useJobCreation = (csrfToken, selectedBuilding) => {
  const [loading, setLoading] = useState(false);
  const [jobUrl, setJobUrl] = useState(null);
  const [showJobModal, setShowJobModal] = useState(false);
  const [showJobLinkModal, setShowJobLinkModal] = useState(false);

  const handleCreateJob = useCallback(
    async (jobDetails) => {
      setLoading(true);
      try {
        const response = await fetch("/api/aroflo/create_task", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify({
            buildingId: selectedBuilding.id,
            taskname: jobDetails.taskname,
            description: jobDetails.description,
            duedate: jobDetails.duedate,
          }),
          credentials: "include",
        });

        if (response.ok) {
          const result = await response.json();
          setJobUrl(result.url);
          setShowJobModal(false);
          setShowJobLinkModal(true);
          return true;
        } else {
          const errorText = await response.text();
          console.log("Failed to create job:", errorText);
          return false;
        }
      } catch (error) {
        console.error("Error creating job:", error);
        return false;
      } finally {
        setLoading(false);
      }
    },
    [csrfToken, selectedBuilding.id]
  );

  return {
    handleCreateJob,
    jobUrl,
    showJobModal,
    setShowJobModal,
    showJobLinkModal,
    setShowJobLinkModal,
    closeJobModal: useCallback(() => setShowJobModal(false), []),
    closeJobLinkModal: useCallback(() => setShowJobLinkModal(false), []),
    loading,
  };
};
