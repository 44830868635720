import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

const JobCreationModal = ({
  show,
  handleClose,
  handleCreateJob,
  loading,
  selectedCameras,
}) => {
  const [jobDetails, setJobDetails] = useState({
    description: "",
    taskname: "",
    duedate: "",
    cameras: [],
  });

  const [selectedCameraDetails, setSelectedCameraDetails] = useState([]);
  const [manualDescription, setManualDescription] = useState(""); // Store manual input separately

  // Get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  // Update jobDetails when selectedCameras prop changes
  useEffect(() => {
    setJobDetails((prevDetails) => ({
      ...prevDetails,
      cameras: selectedCameras,
    }));
  }, [selectedCameras]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "description") {
      setManualDescription(value);
    }
    setJobDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (camera) => {
    let updatedSelectedCameras = [...selectedCameraDetails];
    if (updatedSelectedCameras.includes(camera.camera_number)) {
      updatedSelectedCameras = updatedSelectedCameras.filter(
        (c) => c !== camera.camera_number
      );
    } else {
      updatedSelectedCameras.push(camera.camera_number);
    }

    setSelectedCameraDetails(updatedSelectedCameras);

    // Ensure only one "Selected Cameras" line in the description
    const descriptionWithoutSelectedCameras = manualDescription.replace(
      /Selected Cameras:.*\n?/,
      ""
    );

    // Update description with manual input and selected cameras
    const updatedDescription = `Selected Cameras: ${updatedSelectedCameras.join(
      ", "
    )}\n${descriptionWithoutSelectedCameras.trim()}`.trim();

    setJobDetails((prevDetails) => ({
      ...prevDetails,
      description: updatedDescription, // Update description field
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const success = await handleCreateJob(jobDetails); // Pass job details to parent function
    if (success) {
      clearForm();
    }
  };

  // Clear form fields
  const clearForm = () => {
    setJobDetails({
      description: "",
      taskname: "",
      duedate: "",
      cameras: [],
    });
    setSelectedCameraDetails([]);
    setManualDescription(""); // Reset manual input
  };

  // Wrap the parent's handleClose to also clear the form when modal is closed
  const handleCloseAndClear = () => {
    clearForm(); // Clear form fields
    handleClose(); // Call the parent's handleClose function
  };

  return (
    <Modal show={show} onHide={handleCloseAndClear} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="jobTaskname" className="mt-3">
            <Form.Label>Taskname</Form.Label>
            <Form.Control
              type="text"
              name="taskname"
              value={jobDetails.taskname}
              onChange={handleInputChange}
              placeholder="Taskname"
              required
            />
          </Form.Group>

          <Form.Group controlId="jobDuedate" className="mt-3">
            <Form.Label>Due Date</Form.Label>
            <Form.Control
              type="date"
              name="duedate"
              value={jobDetails.duedate}
              onChange={handleInputChange}
              placeholder="YYYY-MM-DD"
              min={getTodayDate()}
              required
            />
          </Form.Group>
          <Form.Group controlId="jobDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              rows={6}
              value={jobDetails.description}
              onChange={handleInputChange}
              placeholder="Enter job description"
              className="textarea-top-align"
              required
            />
          </Form.Group>

          {/* Display Selected Cameras */}
          {Array.isArray(selectedCameras) && selectedCameras.length > 0 && (
            <Form.Group controlId="selectedCameras">
              <Form.Label>Select Cameras</Form.Label>
              <Row>
                {selectedCameras.map((camera) => (
                  <Col key={camera.camera_id} xs={6} sm={4}>
                    <Form.Check
                      inline
                      label={`Camera: ${camera.camera_number}`}
                      checked={selectedCameraDetails.includes(
                        camera.camera_number
                      )}
                      onChange={() => handleCheckboxChange(camera)}
                    />
                  </Col>
                ))}
              </Row>
            </Form.Group>
          )}

          <Button
            variant="primary"
            type="submit"
            className="mt-3"
            disabled={loading}
          >
            {loading ? "Creating Job..." : "Create Job"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default JobCreationModal;
