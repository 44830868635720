import React, { useState } from "react";
import { SelectDropdown } from "../SelectDropdown";
import { useCsrfToken } from "../utils/useCsrfToken";
import { Form, Button, Container } from "react-bootstrap";

/**
 * Renders a form to add a new camera to the database.
 *
 * @returns {JSX.Element} The form component.
 */
export const AddCamera = () => {
  const [cameraName, setCameraName] = useState("");
  const [cameraNumber, setCameraNumber] = useState("");
  const [camid, setCamid] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [active, setActive] = useState(false); // Default to true
  const [selectedBuilding, setSelectedBuilding] = useState({});
  const [loading, setLoading] = useState(false);
  const { csrfToken, csrfLoading, csrfError } = useCsrfToken();

  // Handle building selection change
  const handleBuildingChange = (building) => {
    setSelectedBuilding(building); // Set the selected building (id and name)
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const cameraData = {
      camera_name: cameraName,
      camera_number: cameraNumber ? parseInt(cameraNumber) : null,
      camid: camid,
      latitude: latitude ? parseFloat(latitude) : null,
      longitude: longitude ? parseFloat(longitude) : null,
      active: active,
      location: selectedBuilding.id, // Send the building ID
    };

    try {
      const response = await fetch("/api/add_camera", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify(cameraData),
        credentials: "include", // Include authentication cookies in the request
      });

      const result = await response.json();

      if (response.ok) {
        // Reset form and notify user of success
        setCameraName("");
        setCameraNumber("");
        setCamid("");
        setLatitude("");
        setLongitude("");
        setActive(false);
        setSelectedBuilding({ id: "", name: "" });
        alert("Camera added successfully!");
      } else {
        alert("Error adding camera: " + result.error);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form: " + result.error);
    } finally {
      setLoading(false);
    }
  };

  // Handle CSRF loading or errors
  if (csrfLoading) {
    return <div>Loading CSRF token...</div>;
  }

  if (csrfError) {
    return <div>Error loading CSRF token: {csrfError}</div>;
  }

  return (
    <Container className="addcamera-form">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-2" controlId="camid">
          <Form.Label>Cam ID (RPS Asset No.)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Cam ID"
            value={camid}
            onChange={(e) => setCamid(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="cameraNumber">
          <Form.Label>Camera Number</Form.Label>
          <Form.Control
            type="number"
            placeholder="Camera Number"
            value={cameraNumber}
            onChange={(e) => setCameraNumber(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="cameraName">
          <Form.Label>Camera Name (e.g B050C02U2502)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Camera Name"
            value={cameraName}
            onChange={(e) => setCameraName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="latitude">
          <Form.Label>Latitude</Form.Label>
          <Form.Control
            type="text"
            placeholder="Latitude"
            value={latitude}
            onChange={(e) => setLatitude(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="longitude">
          <Form.Label>Longitude</Form.Label>
          <Form.Control
            type="text"
            placeholder="Longitude"
            value={longitude}
            onChange={(e) => setLongitude(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="active" className="mb-2">
          <Form.Check
            type="checkbox"
            label="Active"
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="location">
          <Form.Label>Select Location</Form.Label>
          <SelectDropdown
            source="building"
            value={
              selectedBuilding.id
                ? { value: selectedBuilding.id, label: selectedBuilding.name }
                : { value: null, label: "Select building" }
            } // Pass the selected building value
            allowNotAssigned={true}
            onChange={handleBuildingChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? "Loading..." : "Add Camera"}
        </Button>
      </Form>
    </Container>
  );
};
